// @refresh reset

import React from "react"
import { LocalizedLink as Link } from "gatsby-theme-i18n"

import PageWrapper from "../../components/PageWrapper"
import SEO from "../../components/SEO"
import { t, Trans } from "@lingui/macro"

import imgBanner from "../../assets/image/png/service-consulting-banner.png"

import DiscoveryWorkshopSection from "../../sections/services/DiscoveryWorkshop"

const Consulting = ({ location }) => {
    return (
        <>
            <PageWrapper>
                <SEO
                    pathname={location.pathname}
                    title={t`Consulting`}
                    description={""}
                />




                <div className="tw-px-4 tw-pt-32 tw-pb-16 tw-bg-white section md:tw-pt-32">
                    <div className="tw-container tw-mx-auto lg:tw-max-w-4xl">
                        <h1 className="tw-mb-4 tw-text-6xl tw-font-bold tw-text-left tw-text-black tw-font-title sm:tw-text-center">
                            Consulting
                        </h1>
                        <p className="tw-text-base tw-italic tw-text-left sm:tw-text-center">
                            Strategische und operative Beratung bei der Entwicklung Ihrer ML-Teams und -Systeme.
                        </p>
                    </div>
                </div>


                <div className="tw-px-2 tw-pb-8 tw-bg-white section">
                    <div className="tw-container tw-relative tw-mx-auto lg:tw-max-w-4xl">
                        <img src={imgBanner} className="tw-object-cover tw-object-top tw-w-full tw-aspect-video" />
                    </div>
                </div>



                {/* Textvariante A von Woldemar */}


                <div className="tw-px-4 tw-py-8 tw-bg-white section">
                    <div className="tw-container tw-max-w-xl tw-mx-auto">
                        <p className="tw-mt-3 tw-text-base tw-text-left">
                            Das AMAI Consulting basiert auf Erkenntnissen aus zahlreichen Projekten mit einem hohen Anteil an Künstlicher Intelligenz. Aus der Praxis wissen wir genau, wo die Risiken und Chancen agiler Projekte mit intelligenter Software verborgen sind. Im Rahmen der Consulting-Leistungen für verschiedene Projektphasen begleiten wir Projektmanager, Product Owner, Tribe Leads, CTOs und Softwareteams bei der Identifikation von Projektrisiken und Entwicklung von adäquaten Lösungen, um den Projekterfolg zu sichern. Mit der Unterstützung durch AMAI führen Sie ihre Projekte flexibel, zeitgerecht und erfolgreich zum Ziel.
                        </p>
                    </div>
                </div>


                <div className="tw-px-4 tw-py-8 tw-bg-white section">
                    <div className="tw-container tw-max-w-xl tw-mx-auto">
                        <h4 className="tw-text-lg tw-font-medium">
                            AI Opportunity
                        </h4>
                        <p className="tw-mt-3 tw-text-base tw-text-left">
                            Unsere Beratungsleistung beginnt mit Ihrer Absicht Machine Learning in Ihrer Organisation einzusetzen. Mit der Erfahrung aus vielen Projekten verschiedener Größen helfen wir Ihnen in geeignete Gesprächs- und Umsetzungsformaten beim Finden geeigneter Anwendungsfälle, Durchführen stichhaltiger Machbarkeitsstudien (PoC) sowie beim Kalkulieren von realistischen Budgets.
                        </p>
                    </div>
                </div>

                {/*<DiscoveryWorkshopSection />*/}


                <div className="tw-px-4 tw-py-8 tw-bg-white section">
                    <div className="tw-container tw-max-w-xl tw-mx-auto">
                        <h4 className="tw-text-lg tw-font-medium">
                            AI Entwicklungsprozess
                        </h4>
                        <p className="tw-mt-3 tw-text-base tw-text-left">
                            Unsere Kunden schätzen AMAI für eine fundierte Beratung bei der Entscheidungsfindung durch den gesamten Entwicklungsprozess. Das detaillierte Verstehen der Domäne, die effektive Exploration von Daten, dem Gestalten effektiver Delivery Roadmaps, der Identifikation und Auswahl geeigneter ML-Komponenten sowie Bibliotheken sind die Kerntätigkeiten zur Entwicklung robuster Machine Learning Systeme. Wir sind erfolgreich darin ML-Entwicklungsprozesse zu analysieren und effektiv zu gestalten. Das führt zur Maximierung der Performance von ML-Teams, Optimierung der Modell-Qualität und zur Minimierung der „time-to-delivery“.
                        </p>

                    </div>
                </div>


                <div className="tw-px-4 tw-py-8 tw-bg-white section">
                    <div className="tw-container tw-max-w-xl tw-mx-auto">
                        <h4 className="tw-text-lg tw-font-medium">
                            AI Organisation
                        </h4>
                        <p className="tw-mt-3 tw-text-base tw-text-left">
                            Mit einem ML-Entwicklerteam sichern sich viele innovationsorientierte Unternehmen bereits heute ihre Zukunft in der digitalen Welt von Morgen. AMAI ist erfahren darin Führungskräfte bei der Gestaltung, Auswahl und Besetzung effektiver ML-Entwicklerteams zu unterstützen. Darüber hinaus berät AMAI bei der Einführung zielgerichteter agiler Arbeitsmethoden.
                        </p>
                    </div>
                </div>

            </PageWrapper>
        </>
    )
}
export default Consulting
